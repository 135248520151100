import { atom } from "jotai";
import {
  ProposalCard,
  DatabaseProposal,
  defaultProposalCard,
} from "@/app/types/proposals";

const placeholderProposals: ProposalCard[] = Array(3).fill({
  abstainVotes: "-",
  againstVotes: "-",
  calldatas: [],
  createdAt: new Date(),
  description: "Loading...",
  forVotes: "-",
  proposalEndsAt: new Date(),
  proposalId: "0xplaceholder" as `string`,
  proposalSnapshot: "-",
  proposalState: {
    text: "Loading...",
    bgColor: "bg-gray-200",
    textColor: "text-gray-500",
  },
  proposalTitle: "Loading...",
  proposalVotes: null,
  proposedBy: "0xplaceholder" as `0x${string}`,
  quorum: "-",
  targets: ["0xplaceholder" as `0x${string}`],
  totalVoters: 0,
  values: ["-"],
});

export const proposalPlaceholdersAtom =
  atom<ProposalCard[]>(placeholderProposals);

export const initialProposalsAtom = atom<ProposalCard[]>([]);

export const cachedProposalsAtom = atom<ProposalCard[]>([]);

export const proposalsLoadingAtom = atom(false);

export const allProposalsAtom = atom<ProposalCard[]>([]);

export const passedProposalsAtom = atom<ProposalCard[]>([]);

export const submitProposalAtom = atom<ProposalCard>(defaultProposalCard);

export const proposalCountAtom = atom({
  total: 0,
  passed: 0,
  failed: 0,
});

export const totalVotesAtom = atom(0);

//!DB LOGIC
export const proposalDraftAtom = atom<DatabaseProposal>({
  id: 0,
  title: "",
  description: "",
  wallet_address: "",
  is_draft: true,
});

export const proposalDbAtom = atom<DatabaseProposal[]>([]);

//!DB LOGIC
export const draftSavedAtom = atom(false);
