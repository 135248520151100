"use client";

import { Interface, ethers, Log } from "ethers";
import { governorABI, timelockABI, tokenABI } from "@/app/abi";
import { ProposalCard } from "@/app/types/proposals";
import {
  getProposalState,
  proposalMapping,
} from "@/app/helpers/proposalLogHelpers";

const contractAddress = process.env.NEXT_PUBLIC_GOVERNOR_ADDRESS!;
const provider = new ethers.JsonRpcProvider(
  process.env.NEXT_PUBLIC_PROVIDER_URL,
);

const contractABIs = {
  [process.env.NEXT_PUBLIC_GOVERNOR_ADDRESS!]: governorABI,
  [process.env.NEXT_PUBLIC_TIMELOCK_ADDRESS!]: timelockABI,
  [process.env.NEXT_PUBLIC_TOKEN_ADDRESS!]: tokenABI,
};

const MAX_BLOCK_RANGE = 2000;
const RATE_LIMIT_DELAY = 500;
const PARALLEL_BLOCK_QUERIES = 5;
const MAX_RETRIES = 10;
const MAX_BACKOFF = 64000;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const exponentialBackoff = async (
  fn: Function,
  retries = MAX_RETRIES,
): Promise<any> => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) {
      throw error;
    }
    const delayMs = Math.min(
      2 ** (MAX_RETRIES - retries) * 1000 + Math.random() * 1000,
      MAX_BACKOFF,
    );
    await delay(delayMs);
    return exponentialBackoff(fn, retries - 1);
  }
};

const sanitizeTitle = (title: string) =>
  title.replace(/[!@#^&*+{}\[\]?>]/g, "");

type DecodedVoteLog = {
  voter: string;
  proposalId: string;
  support: string;
  weight: string;
  reason: string;
};

const decodeVoteLogs = (logs: any) => {
  return logs.map((log: any) => {
    const { voter, proposalId, support, weight, reason, params, signature } =
      log.args;

    return {
      voter: voter?.toString() || "",
      proposalId: proposalId?.toString() || "",
      support: support?.toString() || "",
      weight: weight
        ? (BigInt(weight.toString()) / BigInt(10 ** 18)).toLocaleString()
        : "",
      reason: reason ? reason.toString() : "",
      params: params ? params.toString() : "",
      signature: signature ? signature.toString() : "",
    };
  });
};

// Fetch logs from specific blocks
const fetchLogsFromSpecificBlocks = async (
  specificBlocks: number[],
): Promise<Log[]> => {
  const contract = new ethers.Contract(contractAddress, governorABI, provider);
  let allLogs: Log[] = [];

  for (let i = 0; i < specificBlocks.length; i++) {
    const block = specificBlocks[i];

    const logs = await exponentialBackoff(async () => {
      const filter = contract.filters.ProposalCreated();
      const events: Log[] = await contract.queryFilter(filter, block, block);
      return events;
    });
    allLogs = allLogs.concat(logs);
    await delay(RATE_LIMIT_DELAY);
  }

  return allLogs;
};

const fetchLogs = async (
  startBlock: number,
  latestBlock: number,
): Promise<Log[]> => {
  const contract = new ethers.Contract(contractAddress, governorABI, provider);
  const blockBatches: Array<[number, number]> = [];
  let allLogs: Log[] = [];

  for (
    let currentBlock = latestBlock;
    currentBlock >= startBlock;
    currentBlock -= MAX_BLOCK_RANGE
  ) {
    const endBlock: number = Math.max(
      currentBlock - MAX_BLOCK_RANGE + 1,
      startBlock,
    );
    blockBatches.push([endBlock, currentBlock]);
  }

  for (let i = 0; i < blockBatches.length; i += PARALLEL_BLOCK_QUERIES) {
    const currentBatch = blockBatches.slice(i, i + PARALLEL_BLOCK_QUERIES);

    const batchPromises = currentBatch.map(([start, end]) =>
      exponentialBackoff(async () => {
        const filter = contract.filters.ProposalCreated();
        const events: Log[] = await contract.queryFilter(filter, start, end);

        return events;
      }),
    );

    try {
      const batchLogs = await Promise.all(batchPromises);
      allLogs = allLogs.concat(...batchLogs.flat());
    } catch (error) {
      console.error(
        `Error fetching logs for block range [${currentBatch[0][0]} - ${currentBatch[currentBatch.length - 1][1]}]:`,
        error,
      );
    }

    await delay(RATE_LIMIT_DELAY);
  }

  return allLogs;
};

const fetchVoteLogs = async () => {
  const contract = new ethers.Contract(contractAddress, governorABI, provider);
  const latestBlock = await exponentialBackoff(() => provider.getBlockNumber());
  const startBlock = Number(process.env.NEXT_PUBLIC_PROPOSALS_START_BLOCK!);
  // Fetch known voting blocks
  const knownVotingBlocks =
    process.env.NEXT_PUBLIC_VOTING_BLOCKS?.split(",").map(Number) || [];
  let allLogs: any = [];

  const voteEventFilters = [
    contract.filters.VoteCast(),
    contract.filters.VoteCastWithParams(),
  ];

  // Fetch logs from the known voting blocks
  for (let block of knownVotingBlocks) {
    const logs = await Promise.all(
      voteEventFilters.map((filter) =>
        exponentialBackoff(async () => {
          const events = await contract.queryFilter(filter, block, block);
          return events.flat();
        }),
      ),
    );
    allLogs = allLogs.concat(...logs.flat());
    await delay(RATE_LIMIT_DELAY); // Small delay to avoid rate limiting
  }

  // Fetch logs from the block range (from latestBlock back to startBlock)
  for (
    let currentBlock = latestBlock;
    currentBlock >= startBlock;
    currentBlock -= MAX_BLOCK_RANGE
  ) {
    const endBlock: number = Math.max(
      currentBlock - MAX_BLOCK_RANGE + 1,
      startBlock,
    );
    const logs = await Promise.all(
      voteEventFilters.map((filter) =>
        exponentialBackoff(async () => {
          const events = await contract.queryFilter(
            filter,
            endBlock,
            currentBlock,
          );
          return events.flat();
        }),
      ),
    );
    allLogs = allLogs.concat(...logs.flat());
    await delay(RATE_LIMIT_DELAY); // Small delay to avoid rate limiting
  }

  // Parse logs and explicitly attach blockNumber after parsing
  const parsedLogs = allLogs
    .map((log: any) => {
      try {
        const parsedLog = contract.interface.parseLog(log);
        return { ...parsedLog, blockNumber: log.blockNumber };
      } catch (error) {
        console.log("Error parsing log:", error);
        return null;
      }
    })
    .filter((log: any) => log !== null);

  return decodeVoteLogs(parsedLogs);
};

const fetchProposalDetails = async (
  logs: Log[],
  voteLogs: DecodedVoteLog[],
): Promise<{
  initialDetails: ProposalCard[];
  remainingDetails: ProposalCard[];
  totalProposals: number;
  passed: number;
  failed: number;
}> => {
  if (logs.length === 0) {
    return {
      initialDetails: [],
      remainingDetails: [],
      totalProposals: 0,
      passed: 0,
      failed: 0,
    };
  }

  const contract = new ethers.Contract(contractAddress, governorABI, provider);
  const totalProposals = logs.length;
  let passed = 0;
  let failed = 0;

  const details: ProposalCard[] = await Promise.all(
    logs.map(async (log) => {
      try {
        // Parse the log to extract the `args` property
        const parsedLog = contract.interface.parseLog(log);
        const args = parsedLog?.args;

        const proposalId =
          (args && args[0]?.toString()) || "Unknown Proposal ID";
        const votes = await contract.proposalVotes(proposalId);
        const state = await contract.state(proposalId);

        const formatVotes = (votes: bigint) =>
          (votes / BigInt(10 ** 18)).toLocaleString();

        const proposalSnapshotBlock =
          await contract.proposalSnapshot(proposalId);
        const proposalDeadlineBlock =
          await contract.proposalDeadline(proposalId);
        const quorum = await contract.quorum(proposalSnapshotBlock);

        const proposalVoteLogs = voteLogs.filter(
          (vote) => vote.proposalId === proposalId,
        );
        const totalVoters = new Set(proposalVoteLogs.map((vote) => vote.voter))
          .size;

        const proposalCreationBlock = log.blockNumber;
        const block = await provider.getBlock(proposalCreationBlock);
        const timestamp = block
          ? new Date(block.timestamp * 1000).toISOString()
          : "Pending";

        const proposalDeadline = await provider.getBlock(proposalDeadlineBlock);
        const proposalEndsAt = proposalDeadline
          ? new Date(proposalDeadline.timestamp * 1000).toISOString()
          : "Pending";

        let proposalTitle: string;
        let description: string;

        if (proposalMapping[proposalId]) {
          proposalTitle = proposalMapping[proposalId].title;
          description = proposalMapping[proposalId].description;
        } else {
          const descriptionParts = (args &&
            args[8]?.toString().split("\n---\n")) || [
            "Unknown Title",
            "No Description",
          ];
          proposalTitle =
            descriptionParts.length > 1
              ? sanitizeTitle(descriptionParts[0].replace("Title: ", ""))
              : sanitizeTitle((args && args[8]?.toString()) || "No Title");
          description =
            descriptionParts.length > 1
              ? descriptionParts[1]
              : (args && args[8]?.toString()) || "No Description";
        }

        const targets =
          (args && args[2]?.map((target: any) => target.toString())) || [];
        const calldatas =
          (args && args[5]?.map((data: any) => data.toString())) || [];
        const values =
          (args && args[3]?.map((value: any) => value.toString())) || [];

        const decodedCalldatas = calldatas.map(
          (calldata: `0x${string}`, index: number) => {
            const target = targets[index];
            const contractABI = contractABIs[target];
            if (!contractABI) {
              return {};
            }

            if (!calldata || calldata === "0x") {
              return {};
            }

            const iface = new Interface(contractABI);
            let parsedTransaction;
            try {
              parsedTransaction = iface.parseTransaction({ data: calldata });
            } catch (error) {
              console.error(
                `Error parsing transaction for target address ${target}:`,
                error,
              );
              return {};
            }

            if (!parsedTransaction) {
              return {};
            }

            const methodName = parsedTransaction?.name;
            const inputTypes = parsedTransaction?.fragment.inputs.map(
              (input: any) => input.type,
            );

            if (!inputTypes) {
              return {};
            }

            let decoded;
            try {
              decoded = iface.decodeFunctionData(methodName, calldata);
            } catch (error) {
              return {};
            }

            return {
              method: methodName,
              types: inputTypes,
              values: decoded,
              encodedData: calldata,
            };
          },
        );

        if (state === 4 || state === 7) {
          passed++;
        } else if (state === 3 || state === 2) {
          failed++;
        }

        return {
          abstainVotes: formatVotes(votes.abstainVotes),
          againstVotes: formatVotes(votes.againstVotes),
          forVotes: formatVotes(votes.forVotes),
          proposalId,
          proposalState: getProposalState(Number(state)),
          totalVoters,
          votesWithReason: proposalVoteLogs,
          calldatas: decodedCalldatas,
          createdAt: new Date(timestamp),
          description,
          proposalEndsAt: new Date(proposalEndsAt),
          proposalSnapshot: proposalSnapshotBlock.toString(),
          proposalTitle,
          proposalVotes: votes,
          proposedBy: (args && args[1]?.toString()) || "Unknown Proposer",
          quorum: (
            BigInt(quorum.toString()) / BigInt(10 ** 18)
          ).toLocaleString(),
          targets,
          values,
          encodedCalldatas: calldatas,
        } as ProposalCard;
      } catch (error) {
        console.log("Error fetching proposal details for log:", log, error);
        throw error;
      }
    }),
  );

  const sortedDetails = details.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const initialDetails = sortedDetails.slice(0, 3);
  const remainingDetails = sortedDetails.slice(3);

  return { initialDetails, remainingDetails, totalProposals, passed, failed };
};

// New function for fetching initial proposals
export const fetchInitialProposals = async (): Promise<{
  initialDetails: ProposalCard[];
  totalProposals: number;
  passed: number;
  failed: number;
}> => {
  try {
    const specificBlocks = process.env.NEXT_PUBLIC_PROPOSALS_SPECIFIC_BLOCKS
      ? process.env.NEXT_PUBLIC_PROPOSALS_SPECIFIC_BLOCKS.split(",").map(Number)
      : [];

    const startBlock = Number(process.env.NEXT_PUBLIC_PROPOSALS_START_BLOCK);
    const latestBlock = await provider.getBlockNumber();

    const specificBlockLogs = await fetchLogsFromSpecificBlocks(specificBlocks);
    const remainingLogs = await fetchLogs(startBlock, latestBlock);

    const allLogs = specificBlockLogs.concat(remainingLogs);
    const voteLogs = await fetchVoteLogs();

    const { initialDetails, totalProposals, passed, failed } =
      await fetchProposalDetails(allLogs, voteLogs);

    return {
      initialDetails,
      totalProposals,
      passed,
      failed,
    };
  } catch (error) {
    console.error("Error in fetchInitialProposals:", error);
    throw error;
  }
};

// New function for fetching remaining proposals
export const fetchRemainingProposals = async (): Promise<{
  remainingDetails: ProposalCard[];
}> => {
  try {
    const specificBlocks = process.env.NEXT_PUBLIC_PROPOSALS_SPECIFIC_BLOCKS
      ? process.env.NEXT_PUBLIC_PROPOSALS_SPECIFIC_BLOCKS.split(",").map(Number)
      : [];

    const startBlock = Number(process.env.NEXT_PUBLIC_PROPOSALS_START_BLOCK);
    const latestBlock = await provider.getBlockNumber();

    const specificBlockLogs = await fetchLogsFromSpecificBlocks(specificBlocks);
    const remainingLogs = await fetchLogs(startBlock, latestBlock);

    const allLogs = specificBlockLogs.concat(remainingLogs);
    const voteLogs = await fetchVoteLogs();

    const { remainingDetails } = await fetchProposalDetails(allLogs, voteLogs);

    return {
      remainingDetails,
    };
  } catch (error) {
    console.error("Error in fetchRemainingProposals:", error);
    throw error;
  }
};
