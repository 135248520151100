import { ReactNode } from "react";

export interface ProposalCard {
  abstainVotes: string;
  againstVotes: string;
  calldatas: {
    method: string;
    types: string[];
    values: any[];
    encodedData: string;
  }[];
  createdAt: Date;
  description: string;
  forVotes: string;
  proposalEndsAt: Date;
  proposalId: string;
  proposalSnapshot: string;
  proposalState: {
    text: string;
    bgColor: string;
    textColor: string;
  };
  proposalTitle: string;
  proposalVotes: any | null;
  votesWithReason?: any[];
  proposedBy: `0x${string}`;
  quorum: string;
  targets: `0x${string}`[];
  totalVoters: number;
  values: string[];
  encodedCalldatas?: string[];
}

export const defaultProposalCard: ProposalCard = {
  abstainVotes: "0",
  againstVotes: "0",
  calldatas: [
    {
      method: "",
      types: [],
      values: [],
      encodedData: "",
    },
  ],
  createdAt: new Date(),
  description: "",
  forVotes: "0",
  proposalEndsAt: new Date(),
  proposalId: "0123" as string,
  proposalSnapshot: "",
  proposalState: {
    text: "",
    bgColor: "",
    textColor: "",
  },
  proposalTitle: "Loading...",
  proposalVotes: null,
  votesWithReason: [],
  proposedBy: "0x" as `0x${string}`,
  quorum: "",
  targets: ["0x" as `0x${string}`],
  totalVoters: 0,
  values: [""],
  encodedCalldatas: [],
};

export interface DatabaseProposal {
  id?: number;
  title?: string;
  description?: string;
  is_draft?: boolean;
  wallet_address?: string;
}
export interface Proposal {
  proposalId: string;
  title?: string;
  fullProposalId?: string;
  description: string;
  is_draft?: boolean;
  walletAddress?: `0x${string}`;
  targets: `0x${string}`[];
  values: string[];
  calldatas: string[];
  proposalVotes: any;
  forVotes: string;
  againstVotes: string;
  abstainVotes: string;
  votesWithReason?: any[];
  statusBgColor: string;
  statusTextColor: string;
  status: string;
  createdAt: Date;
  proposedBy: `0x${string}`;
}

export interface ActionInput {
  name: string;
  type: string;
  internalType?: string;
  value?: string[];
}

export interface Action {
  target: `0x${string}`;
  method: string;
  show: string;
  inputs: ActionInput[];
}

export type MethodInputs = {
  [key: string]: string[];
};

export type MethodInput = {
  name: string;
  type: string;
  internalType: string;
};

export type ContractMethod = {
  name: string;
  show: string;
  inputs: {
    internalType: string;
    name: string;
    show: string;
    type: string;
  }[];
  outputs?: { name: string; type: string }[];
  stateMutability?: string;
};

export interface DatabaseProposal {
  id?: number;
  proposalId?: string;
  title?: string;
  description?: string;
  is_draft?: boolean;
  wallet_address?: string;
}

export interface UIProposal {
  statusBgColor?: string;
  statusTextColor?: string;
}

export type ProposalType =
  | "Process Modifications"
  | "Protocol Upgrades"
  | "Funding Requests"
  | "Informational";

export type ProposalMapping = {
  [key: string]: {
    title: string;
    description: string;
  };
};
