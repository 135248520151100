"use client";

import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  fetchInitialProposals,
  fetchRemainingProposals,
} from "@/app/actions/fetchProposals";
import { useSetAtom, useAtomValue } from "jotai";
import {
  allProposalsAtom,
  cachedProposalsAtom,
  proposalCountAtom,
  totalVotesAtom,
  initialProposalsAtom,
  passedProposalsAtom,
} from "@/app/atoms/proposals";
import { ProposalCard } from "@/app/types/proposals";

export const useFetchProposals = () => {
  const setAllProposals = useSetAtom(allProposalsAtom);
  const setCachedProposals = useSetAtom(cachedProposalsAtom);
  const setProposalCount = useSetAtom(proposalCountAtom);
  const setTotalVotes = useSetAtom(totalVotesAtom);
  const setInitialProposals = useSetAtom(initialProposalsAtom);
  const setPassedProposals = useSetAtom(passedProposalsAtom);

  const initialProposals = useAtomValue(initialProposalsAtom);
  const [loading, setLoading] = useState(true);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);

  const mutation = useMutation({
    mutationFn: async () => {
      const initialResults = await fetchInitialProposals();
      if (initialResults.initialDetails && initialResults.totalProposals) {
        setInitialProposals(initialResults.initialDetails);
        setCachedProposals(initialResults.initialDetails);

        const totalVotes = initialResults.initialDetails.reduce(
          (acc: number, detail: ProposalCard) =>
            acc + parseInt(detail.forVotes) + parseInt(detail.againstVotes),
          0,
        );

        setProposalCount({
          total: initialResults.totalProposals,
          passed: initialResults.passed,
          failed: initialResults.failed,
        });

        setTotalVotes(totalVotes);
        setIsInitialLoaded(true);
        setLoading(false);
      }

      const remainingResults = await fetchRemainingProposals();
      if (remainingResults.remainingDetails) {
        const allProposalsCombined = [
          ...initialResults.initialDetails,
          ...remainingResults.remainingDetails,
        ];

        setAllProposals(allProposalsCombined);
        setCachedProposals(allProposalsCombined);

        const totalPassed = allProposalsCombined.filter(
          (detail: ProposalCard) =>
            detail.proposalState.text === "Succeeded" ||
            detail.proposalState.text === "Executed",
        );

        const totalFailed = allProposalsCombined.filter(
          (detail: ProposalCard) =>
            detail.proposalState.text === "Defeated" ||
            detail.proposalState.text === "Canceled",
        ).length;

        setPassedProposals(totalPassed);

        setProposalCount({
          total: allProposalsCombined.length,
          passed: totalPassed.length,
          failed: totalFailed,
        });
      }
    },
    onError: (error) => {
      console.error("Error fetching proposals:", error);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!initialProposals.length && !isInitialLoaded) {
      mutation.mutate();
    } else if (isInitialLoaded) {
      setLoading(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initialProposals.length, isInitialLoaded]);

  return { loading, mutation };
};

export default useFetchProposals;
